
import { Command } from '../components/Command'
import React from 'react'
const { commands } = require('../data/commands.json')

export const Commands = () => {
    const [cmd, setCmd] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const switcher = (val) => {
        setCmd(val)
    }
    const openNavbar = () => {
        if (open) {
            setOpen(false)

            document.getElementById("myCommandBar").style.width = "0"
            document.getElementById("myCommandBar").style.display = "hidden"
        }
        else {
            setOpen(true)
            document.getElementById("myCommandBar").style.width = "12rem"
            document.getElementById("myCommandBar").style.display = "block"
        }
    }


    return (
        <div className="flex bg-white dark:bg-[#262A33]">
            <div className="bg-white dark:bg-[#262A33]">
                <div className=" transition-all drop-shadow-2xl dark:text-white md:block hidden ">
                    <div className="mx-10 mt-5 transition-all xl:text-lg text-sm">
                        <div>
                            <p className="hover:cursor-pointer hover:opacity-80 transition-all mt-2 ml-5 text-black/60 dark:text-white/80" onClick={() => setCmd(null)}>Start Page</p>
                            <p className="xl:text-2xl text-lg">Customisation</p>
                            <div className="mt-2 ml-5 text-black/60 dark:text-white/80">
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Setup</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Welcome</p>
                            </div>
                        </div>
                        <div>
                            <p className="xl:text-2xl text-lg">Moderation</p>
                            <div className="mt-2 ml-5 text-black/60 dark:text-white/80">
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Kick</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Ban</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Mute</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Lock</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Unlock</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Whois</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Logs</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Timeout</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Clear</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Unmute</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>warn</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>warnings</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>softban</p>
                            </div>
                        </div>
                        <div>
                            <p className="xl:text-2xl text-lg">Tag System</p>
                            <div className="mt-2 ml-5 text-black/60 dark:text-white/80">
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Create</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Edit</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>List</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>View</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Delete</p>
                            </div>
                        </div>
                        <div>
                            <p className="xl:text-2xl text-lg">Other</p>
                            <div className="mt-2 ml-5 text-black/60 dark:text-white/80  ">
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Embed</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Help</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Ping</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Activity</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Invite</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Vote</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Serverinfo</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Ticket</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed h-full z-20 bg-[#F5EAE4] dark:bg-[#191B22] md:hidden block">
                <div className="h-6 w-6 text-center items-center rounded-sm mt-1">
                    <button className="text-white" onClick={() => openNavbar()}>&#9776;</button>
                </div>
            </div>
            <div className="fixed h-screen top-0 left-0 z-10 overflow-x-hidden transition-all md:hidden block bg-[#F5EAE4] dark:bg-[#191B22]" >
                <div className="h-screen float-left transition-all w-0" id="myCommandBar">
                    <div className="text-white ml-10 text-sm">
                        <div>
                            <p className="hover:cursor-pointer hover:opacity-80 transition-all mt-9 text-black/60 dark:text-white/80" onClick={() => setCmd(null)}>Start Page</p>
                            <p className="text-lg">Customisation</p>
                            <div className="mt-2 text-black/50 dark:text-white/80">
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Setup</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Welcome</p>
                            </div>
                        </div>
                        <div>
                            <p className="text-lg">Moderation</p>
                            <div className="mt-2 text-black/60 dark:text-white/80">
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Kick</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Ban</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Mute</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Lock</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Unlock</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Whois</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Logs</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Timeout</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Clear</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>warn</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>warnings</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>softban</p>
                            </div>
                        </div>
                        <div>
                            <p className="text-lg">Tag System</p>
                            <div className="mt-2 text-black/60 dark:text-white/80">
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Create</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Edit</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>List</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>View</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Delete</p>
                            </div>
                        </div>
                        <div>
                            <p className="text-lg">Other</p>
                            <div className="mt-2 text-black/60 dark:text-white/80  ">
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Embed</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Help</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Ping</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Activity</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Invite</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Vote</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Serverinfo</p>
                                <p className="hover:cursor-pointer hover:opacity-80 transition-all" onClick={(e) => switcher(e.target.innerText)}>Ticket</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:ml-0 ml-5 bg-gray-100/50 dark:bg-[#22252E]">
                {cmd ? (
                    <div className="md:ml-10 mx-5 mt-7">
                        <Command cmd={commands[cmd.toLowerCase()]} />
                    </div>
                ) : (
                    <div className="md:ml-10 mx-5 mt-7 dark:text-white  xl:text-4xl md:text-2xl text-xl">
                        <div className="">
                            <p className="">Start Page</p>
                            <div className="flex text-lg mt-2">
                                {/* <p className="opacity-80 mr-2">About:</p> */}
                                <p className="opacity-60 xl:text-xl md:text-lg text-sm">This application has been made to moderate a discord community. Byne is an easy to use, multi language, discord moderation bot that offers multiple features and also useful systems.

                                </p>
                            </div>
                            <p className="md:mt-10 mt-2">Features</p>
                            <div className="md:mt-5 mt-2 opacity-60 xl:text-xl md:text-lg text-sm">
                                <p>Settings</p>
                                <p>4 Languages</p>
                                <p>3 Themes</p>
                                <p>Embed Creator</p>
                                <p>Auto Role</p>
                                <p>Logging</p>
                                <p>Powerful Moderation Commands</p>
                                <p>Welcome Message</p>
                                <p>Anti Mute Bypass</p>
                                <p>Confirmation Buttons</p>
                            </div>
                            <div className="md:block hidden">
                                <p className="md:mt-10 mt-2">Navigation</p>
                                <p className="opacity-60 xl:text-xl md:text-lg text-sm">Navigate through the comands by using the sidebar on the left</p>
                            </div>
                            <p className="md:mt-10 mt-2">Updates</p>
                            <p className="opacity-60 xl:text-xl md:text-lg text-sm">This application is still under development and is getting regular updates. If you want to help the bot' development by providing feedback or suggestions please consider joining our discord server</p>
                            <div className="md:mt-10 mt-2">
                                <p className="">Credits</p>
                                <p className="opacity-60 xl:text-xl md:text-lg text-sm">This discord application / website has been developed by Florian R. also known as BinFloo. Thank you for using our service.</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
}