import { Footer } from "../components/Footer.js"

export const Tos = () => {
    return (
        <div className="dark:bg-[#292B34] bg-[#FFF9F6]">
            <div className="xl:mx-44 md:mx-20 mx-5 mb-10">
                <h1 class="md:text-5xl text-xl mb-10 pt-5 dark:text-white text-black">Terms Of Services</h1>
                <p class="dark:text-white/80 text-black/70 md:text-xl text-sm">Generic Terms of Service Template

                    Please read these terms of service (“terms”, “terms of service”) carefully before using the Byne
                    bot/website<br />
                    (the “service”) operated by the team Byne("us", "we", "our").<br />
                    You (=Discord User, Website Visitor)<br />
                    <br />
                    Conditions of Use<br />
                    <br />
                    We will provide their services to you, which are subject to the conditions stated below in this
                    document.<br />
                    Every time you visit this website, use its services or make a purchase, you accept the following
                    conditions.<br />
                    This is why we urge you to read them carefully.<br />
                    <br />
                    Copyright<br />
                    <br />
                    Content published on this website (digital downloads, images, texts, graphics, logos) is the property
                    of<br />
                    Byne and/or its content creators and protected by international copyright laws. The entire compilation
                    of<br />
                    the content found on this website is the exclusive property of Byne, with copyright authorship for
                    this<br />
                    compilation by Florian (Founder, Creator, of the Website).<br />
                    <br />
                    License and Site Access<br />
                    <br />
                    We grant you a limited license to access and make personal use of this website. You are not allowed to<br />
                    download or modify it. This may be done only with written consent from us.<br />
                    <br />
                    Discord Bot Byne<br />
                    <br />
                    By using the bot, you agree that:<br />
                    - Certain Data as User ID, User tag and Username can and will be saved in our database to grant the bot
                    to<br />
                    work.<br />
                    - We cannot be prosecuted for the input you (the User) insert into the bot.<br />
                    - You agree with these conditions.<br />
                    - You won't abuse the bot in any way.<br />
                    You have the right to get your data deleted. Please reach us out in our support discord server linked on
                    the<br />
                    website.<br />
                    <br />
                    We reserve all rights to terminate accounts, edit or remove content and cancel orders at their sole<br />
                    discretion.
                </p>
            </div>
            <Footer />
        </div>
    )
}