import { Footer } from "../components/Footer.js"

export const NotFound = () => {
    return (
        <div className="text-center dark:text-white text-black h-fit">
            <p className="dark:text-white text-black md:text-9xl text-5xl pt-40">404</p>
            <p className="md:text-4xl text-2xl">Not Found</p>
            <div className="flex justify-center">
                <a className="mt-20 md:text-xl text-lg bg-red-400/10 hover:bg-red-400/20 py-2 px-6 rounded-xl" href="/">Return</a>
            </div>
        </div>
    )
}