import React from "react";

import { DarkModeToggle } from "./DarkModeToggle"


export const AppBar = () => {
    const [open, setOpen] = React.useState(false)
    const openNavbar = () => {
        if (open) {
            setOpen(false)

            document.getElementById("mySidebar").style.width = "0"
        }
        else {
            setOpen(true)
            document.getElementById("mySidebar").style.width = "8rem"
        }
    }

    const img = '/img/Byne.png'
    const [darkMode, setDarkMode] = React.useState(false);
    return (
        <div className="sticky top-0 z-30">
            <div className=" transition-all py-1 dark:bg-[#262A33] dark:text-white text-black bg-white drop-shadow-lg">
                <div className="flow-root md:mx-10 mx-5 h-full">
                    <div className="sm:block hidden">
                        <div className="flex justify-between">
                            <div className="float-left flex">
                                <div className="h-full flex">
                                    <img src={img} className="h-12 w-12 rounded-full" />
                                    <a className="my-auto ml-5 xl:text-xl lg:text-lg md:text-sm" href="/">Byne</a>
                                    <div className=" border-r-2 border-black/50 dark:border-white/60 xl:h-8 h-6 my-auto ml-4"></div>
                                </div>
                                <div className="xl:text-xl lg:text-lg md:text-sm float-left flex">
                                    <div className="flex">
                                        <a href="/" className="my-auto mx-5 hover:opacity-70 transition-all">Home</a>
                                    </div>
                                    <div className="flex">
                                        <a href="/commands" className="my-auto mx-5 hover:opacity-70 transition-all">Commands</a>
                                    </div>
                                    <div className="flex">
                                        <a href="/invite" className="my-auto mx-5 hover:opacity-70 transition-all">Invite</a>
                                    </div>
                                    <div className="flex">
                                        <a href="/discord" className="my-auto mx-5 hover:opacity-70 transition-all">Discord</a>
                                    </div>
                                </div>
                            </div>
                            <div className="float-right flex items-center my-auto">
                                <DarkModeToggle />
                            </div>
                        </div>
                    </div>
                    <div className="sm:hidden block">
                        <div className="float-left ">
                            <img className="h-6 w-6 rounded-full" src={img} />
                        </div>
                        <div className="float-right flex">
                            <div className="mr-3">
                                <DarkModeToggle />
                            </div>
                            <div className="bg-[#414756] h-6 w-6 text-center items-center rounded-sm">
                                <button className="text-white" onClick={() => openNavbar()}>&#9776;</button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div>
                <div className="fixed top-0 right-0 z-20 overflow-x-hidden  transition-all sm:hidden block" >
                    <div className="h-screen float-right bg-[#F5EAE4] dark:bg-[#191B22]  transition-all w-0" id="mySidebar">
                        <button className="dark:text-white text-black/70 text-2xl sm:text-3xl md:text-5xl items-center my-auto transition-all" onClick={() => openNavbar()} >&times;</button>
                        <hr />
                        <div className="mx-1 dark:text-white/70 text-black/60 text-center transition-all">
                            <div className="my-1">
                                <a href="/">Home</a>
                            </div>
                            <div className="my-1">
                                <a href="/commands">Commands</a>
                            </div>
                            <div className="my-1">
                                <a href="#">Invite</a>
                            </div>
                            <div className="my-1">
                                <a href="/discord">Discord</a>
                            </div>
                            <div className="my-1">
                                <a href="/privacy">Privacy</a>
                            </div>
                            <div className="my-1">
                                <a href="/tos">ToS</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}