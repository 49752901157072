import { FaDiscord, FaInstagram, FaAd } from 'react-icons/fa'

export const Footer = () => {
    return (
        <div className="transition-all overflow-x-hidden">
            <svg className="dark:fill-[#32343B] transition-all fill-[#FFFFFF] dark:bg-[#292B34] bg-[#FFF9F6]" width="2779" height="45" viewBox="0 0 2779 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.12146 0.424438L259.61 23.3465L539.788 34.6891C579.468 36.2955 618.873 36.243 658.549 34.5374C831.677 27.0951 1330.16 6.03567 1415.87 6.53701C1495.19 7.00104 1817.29 21.2274 1965.89 27.9625C2016.52 30.2571 2067.02 29.8877 2117.61 26.803L2449.94 6.53701L2778.41 13.4136V44.6726H0.12146V0.424438Z" />
            </svg>
            <div className="dark:bg-[#32343B] bg-[#FFFFFF] transition-all">
                <div className="flex justify-center gap-4 dark:text-white text-black/60 xl:text-3xl md:text-xl text-lg pt-2">
                    <FaDiscord />
                    <FaInstagram />
                    <FaAd />

                </div>
                <p className="xl:text-3xl md:text-xl text-lg dark:text-white text-black/50 font-semibold text-center mt-2 tracking-wider">©Byne</p>
                <div className="flex justify-center">
                    <p className="dark:text-white/70 text-black/50 xl:text-lg md:text-sm text-xs mt-2 text-center w-2/5 tracking-wider">Multifunctional Discord Bot for your Discord server.</p>
                </div>
                <div className="flex justify-center gap-4 xl:text-sm text-xs dark:text-white/40 text-black/30 pb-4">
                    <p>BinFloo © 2022</p>
                    <a href='/tos'>Terms of Services</a>
                    <a href='/privacy'>Privacy Policy</a>
                </div>

            </div>

        </div>
    )
}