export const data = [
    {
        name: 'Moderation',
        icon: 'Moderation',
        description: 'Use our powerful moderation commands to manage your community',
        key: 1
    },
    {
        name: 'Easy to Use',
        icon: 'Easy-to-Use',
        description: 'Our commands are easy to use and are well explained',
        key: 2
    },
    {
        name: 'Multi Language',
        icon: 'Multi-Language',
        description: 'This bot offers you multiple languages to choose, so you will understand this bot better',
        key: 3
    },
    {
        name: 'Slash Commands',
        icon: 'Slash-Commands',
        description: 'Most of the commands are available as slash commands. Important commands are also able as message commands',
        key: 4
    },
    {
        name: 'Buttons',
        icon: 'Buttons',
        description: 'Buttons here, buttons there, buttons everywhere. Who doesn’t like to press buttons?',
        key: 5
    }
]