import React from "react";


export const DarkModeToggle = () => {
    console.log(window.matchMedia("(prefers-color-scheme: dark)"))
    const [theme, setTheme] = React.useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : (window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light'));

    React.useEffect(() => {

        if (theme === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [theme])

    const handleThemeSwitch = () => {
        let nowTheme = theme === 'dark' ? 'light' : 'dark'
        setTheme(theme === 'dark' ? 'light' : 'dark')
        if (nowTheme === 'light') {
            localStorage.setItem("theme", 'light')
        } else {
            localStorage.setItem("theme", 'dark')
        }

    }

    return (
        <div className="flex items-center my-auto h-full">
            <p className="xl:text-xl lg:text-lg md:text-sm mr-4">{theme === 'dark' ? 'Dark' : 'Light'}</p>
            <button className="xl:text-lg text-xs transition-all duration-700 p-1 dark:bg-blue-400/20 dark:hover:bg-blue-400/10 hover:bg-yellow-400/50 bg-yellow-400/20 rounded-lg xl:h-10 xl:w-10 md:h-6 md:w-6" onClick={handleThemeSwitch} >{theme === 'dark' ? '☾' : '☼'}</button>
        </div>
    )
}