export const Command = ({ cmd }) => {
    return (
        <div>
            {cmd?.name ?
                (<div className="sm:mr-12 mr-0 dark:text-white xl:text-4xl md:text-2xl text-xl">
                    <div>
                        <p className="">Description</p>
                        <p className="text-left md:w-3/4 w-full xl:text-xl md:text-lg text-sm mt-2 opacity-70">{cmd?.description}</p>
                    </div>
                    <div className="mt-8">
                        <div className="flow-root">
                            <div className="float-left xl:text-xl md:text-lg text-sm xl:w-2/5 w-full">

                                <p className="mb-5">Usage</p>
                                <div className="drop-shadow-md bg-white dark:bg-[#2D313E] pl-3 opacity-70 py-2 rounded-md mb-5  flex justify-between">
                                    <p>{cmd?.slash}</p>
                                    <img className="w-8 h-8 mr-2" src="/img/icons/Slash.svg" />
                                </div>
                                <div className="drop-shadow-md bg-white dark:bg-[#2D313E] pl-3 opacity-70 py-2 rounded-md mb-5 flex justify-between">
                                    <div>
                                        {cmd?.msg ? (
                                            <p>!{cmd?.msg}</p>
                                        ) : (<p>Not Available</p>)}
                                    </div>
                                    <img className="w-8 h-8 mr-2" src="/img/icons/msg.svg" />
                                </div>
                                <div className="flex text-black/70 dark:text-white/70 text-lg">
                                    <p className="items-end xl:text-xl md:text-lg text-sm">Cooldown:</p>
                                    <p className="opacity-70 mx-2 flex items-end">{cmd?.cooldown}</p>
                                    <p className="opacity-70 flex items-end">Seconds</p>
                                </div>
                                <div className="flex text-black/70 dark:text-white/70 xl:text-xl md:text-lg text-sm">
                                    <p>Aliases:</p>
                                    <div>
                                        {
                                            cmd?.aliases ? (
                                                <p className="ml-2 opacity-70 flex items-end">!{cmd?.aliases}</p>
                                            ) : (
                                                <p className="ml-2 opacity-70 flex items-end">No Aliases For This Command</p>
                                            )
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="xl:block hidden">
                                <img className="block dark:hidden float-right w-2/4 rounded-lg right-0 drop-shadow-xl" src={`/img/commands/${cmd.name}Light.png`} />
                                <img className="hidden dark:block float-right w-2/4 rounded-lg right-0 drop-shadow-xl" src={`/img/commands/${cmd.name}Dark.png`} />
                            </div>
                        </div>
                    </div>
                    <div className="xl:text-lg text-sm">
                        <p className="mt-20">Permissions</p>
                        <div className="flex mt-5">
                            <p className="xl:text-xl md:text-lg text-sm opacity-80">Bot:</p>
                            <p className="ml-2 opacity-60 flex items-end">{cmd?.botPerm}</p>
                        </div>
                        <div className="flex ">
                            <p className="xl:text-xl md:text-lg text-sm opacity-80">Member:</p>
                            <p className=" flex ml-2 opacity-60 items-end">{cmd?.memberPerm ? cmd?.memberPerm : 'No Key Permission Required'}</p>
                        </div>
                    </div>
                </div>)
                : (
                    <></>
                )
            }
        </div>
    )
}