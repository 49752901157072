import { Footer } from "../components/Footer.js"

export const Privacy = () => {
    return (
        <div className="dark:bg-[#292B34] bg-[#FFF9F6]">
            <div className="xl:mx-44 md:mx-20 mx-5 mb-10">
                <h1 class="md:text-5xl text-xl mb-10 pt-5 dark:text-white text-black">Privacy Policy</h1>
                <p class="dark:text-white/80 text-blach/80 md:text-xl text-sm">Privacy Policy<br />
                    <br />
                    Last updated: 07.10.2022<br />
                    (the “service”) operated by the team Byne("us", "we", "our").<br />
                    You (=Discord User, Website Visitor)<br />
                    <br />
                    Data Collection Website<br />
                    <br />
                    We do not collect/use any data from the website<br />
                    <br />
                    Data Collection Discord<br />
                    <br />
                    We will collect following data of an discord user in order to make the bot work:<br />
                    <br />
                    The Discord Identification (ID) is needed to identify the discord user, provide the correct data to a specific user.<br />
                    Discord Username, first shown term of a user is collected to provide the end user (virtual person that uses our service) a name that may be better memorized.<br />
                    The Discord User tag, the username with four digit numbers, unique sign , similar to ID, is being collected to output the end user a name that may be memorized better, to identify a user.<br />
                    <br />
                    Commands that collects user data:<br />
                    <br />
                    Mute, Kick, Timeout, Ban (username, user_tag, user_id)<br />
                    tag create (user_id)
                    <br />
                    We do not share/sell these data to any third party service. This data only purpose is to make the bot<br />
                    commands, that require data, work.<br />
                    If you want to have your data deleted, please join our support server and open a ticket or send us an email:
                    binfloo@diebis.de<br />
                    <br />
                    We reserve all rights to terminate accounts, edit or remove content and cancel orders at their sole<br />
                    discretion.<br />
                </p>
            </div>
            <Footer />
        </div>
    )
}