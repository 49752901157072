export const HomeFeatures = ({ data }) => {
    const img = `/img/icons/${data.icon}.svg`
    return (

        <div className="text-center">
            <p className="tracking-wide font-bold 2xl:text-4xl md:text-xl text-lg">{data.name}</p>
            <div className="2xl:mt-10 mt-0 relative 2xl:h-56 h-36">
                <svg className="dark:fill-[#2E3241] 2xl:w-44 2xl:h-44 h-20 w-20 fill-[#FFF1F0] transition-all absolute left-0 right-0 top-0 bottom-0 mx-auto my-auto" width="178" height="178" viewBox="0 0 178 178" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="88.6647" cy="88.9601" r="88.3668" />
                </svg>
                {
                    data.icon === 'Multi-Language' || data.icon === 'Buttons' ? (

                        <img className=" scale-110 z-10 2xl:w-40 w-20 2xl:h-40 h-20 absolute left-0 top-0 bottom-0 right-0 mx-auto my-auto" src={img} />
                    ) : (
                        <img className=" scale-75 z-10 2xl:w-40 w-20 2xl:h-40 h-20 absolute left-0 top-0 bottom-0 right-0 mx-auto my-auto" src={img} />
                    )

                }
            </div>
            <p className="mx-auto 2xl:w-72 w-36 text-center 2xl:mt-5 mt-0 font-light dark:text-white/50 text-black/50 tracking-wide 2xl:text-2xl text-xs">{data.description}</p>
        </div>
    )
}