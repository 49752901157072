import React from 'react'
import { data } from '../data/features.js'
import { HomeFeatures } from '../components/HomeFeatures.js';
import { Footer } from "../components/Footer.js"


export const Home = () => {

    const href = (loc) => {
        window.location.href = loc
    }

    return (
        <div className="mt-7 overflow-x-hidden">
            <div className="xl:mx-10 mx-4 flex gap-1">
                <div className="float-left lg:w-3/6 sm:w-4/6 w-full">
                    <p className="2xl:text-9xl xl:text-6xl lg:text-5xl md:text-3xl text-2xl dark:text-white/70 text-black/70">Byne</p>
                    <p className="2xl:text-6xl xl:text-4xl lg:text-3xl md:text-2xl text-1xl dark:text-white/60 text-black/60 mt-3">A multi purpose discord bot</p>
                    <p className="2xl:text-3xl xl:text-xl md:text-lg text-xs dark:text-white/40 text-black/40 xl:mt-8 md:mt-4">This is a user friendly, multi language moderation bot that fits all your needs. Byne is easy to use and has a lot of features to create a perfect community!
                    </p>

                    <div className="xl:flex xl:mt-10 mt-5 xl:text-xl text-sm">
                        <button onClick={() => href('./invite')} className="xl:mb-0 mb-2 mr-4 duration-500 xl:py-px py-0 xl:px-14 px-10 text-white dark:bg-[#87FFB0] bg-[#FFB58B] hover:bg-[#FFB58B]/80 dark:hover:bg-[#87FFB0]/80 transition-all rounded-full border-4  border-red-300/20">Invite</button>
                        <button onClick={() => href('./commands')} className="duration-500 xl:py-px py-0 xl:px-8 px-7 text-white dark:bg-[#FF7CAB] bg-[#FF669D] hover:bg-[#FF669D]/80 dark:hover:bg-[#FF7CAB]/80 transition-all rounded-full border-4  border-blue-600/10">Commands</button>
                    </div>
                </div>
                <div className="float-right w-3/6 hidden  lg:flow-root">
                    <div className="float-right">
                        <img className="drop-shadow-lg rounded-lg hidden dark:block" src="./img/startDark.png" />
                        <img className="drop-shadow-lg rounded-lg dark:hidden" src="./img/startLight.png"></img>
                    </div>
                </div>
            </div>
            <div>
                <svg className="dark:fill-[#292B34] bg-white dark:bg-[#22252E] fill-[#FFF9F6] transition-all" width="2779" height="166" viewBox="0 0 2779 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.12146 0.971924L142.123 49.8954C220.297 76.8287 300.682 96.8581 382.354 109.754L386.978 110.484C527.742 132.71 671.055 133.544 812.068 112.958L1205.04 55.5894C1344.8 35.1864 1486.83 35.8236 1626.4 57.4798L1812.5 86.3543C1963.84 109.837 2118.3 103.689 2267.3 68.2511V68.2511C2388.3 39.4722 2513.09 29.9683 2637.05 40.0901L2778.41 51.6326V173.55H0.12146V0.971924Z" />
                </svg>
                <div className="dark:bg-[#292B34] bg-[#FFF9F6] transition-all">
                    <div className="py-10 dark:text-white text-black/40">
                        <div className="hidden xl:visible xl:flex  xl:mx-10 mx-2  justify-between">
                            {
                                data.map((d) => {
                                    return (
                                        <HomeFeatures data={d} key={d.key} />
                                    )
                                })
                            }
                        </div>
                        <div className="block xl:hidden xl:mx-10 mx-2  items-center">
                            {
                                data.map((d) => {
                                    return (
                                        <div className="dark:bg-black/10 bg-white mx-2 mb-5 py-4 rounded-lg">
                                            <HomeFeatures data={d} key={d.key} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}