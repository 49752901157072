import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppBar } from "./components/AppBar"

import { Home } from "./pages/Home"
import { Commands } from "./pages/Commands"
import { Discord } from "./pages/Discord"
import { Invite } from "./pages/Invite"
import { Tos } from "./pages/Tos"
import { Privacy } from "./pages/Privacy"
import { NotFound } from "./pages/NotFound"


function App() {

  return (
    <div className="h-screen dark:bg-[#22252E] bg-white transition-all ">
      <BrowserRouter>

        <AppBar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/commands" element={<Commands />} />
          <Route path="/discord" element={<Discord />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/tos" element={<Tos />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
